import React, { useContext } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"  
import Layout from "../components/layout"
import SEO from "../components/seo"
import arrowDown from '../images/home/arrowDown.svg'
import { LangContext } from "../context/langContext"
import { TextContext } from "../context/textContext"
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from 'react-responsive'

const About = ({data}) => {

  const { lang } = useContext(LangContext);
  const { text } = useContext(TextContext);

  const md = useMediaQuery({ query: '(max-width: 992px)' });
  
  const fetch = (key) => {
    return text.length === 0 ? '' : lang==='en'? text.find(el => el.lang_key === key).en_value : text.find(el => el.lang_key === key).ar_value;
  }

  return (
    <Layout>
      <SEO title="About Us" />
      <div className="arrowDown2 d-none d-lg-block">
        <img src={arrowDown} alt="arrow" width="100%" />
      </div>
      <div className="cover text-center">
        <Img fluid={lang === 'en' ? data.AboutUs_Cover.childImageSharp.fluid:data.AboutUs_CoverAr.childImageSharp.fluid} alt="" style={md?{width:"90%", margin:"0 auto"}:{width:"60%", margin:"0 auto"}} />
      </div>
      <div className="text-center">
        <p className="header2 yellow mt-5 mb-0"><FormattedMessage id="industry" /></p>
        <p className="header1 yellow"><FormattedMessage id="mootamayezoon" /></p>
        <p className="body grey">{fetch('2about01')}</p>
        <div className="textContainer">
          <div className={lang === 'en' ? "text-left" : "text-right"}>
            <p className="header2 white">{fetch('2about02')}</p>
            <p className="header3 white">{fetch('2about03')}</p>
            <p className="header3 white">{fetch('2about04')}</p>
          </div>
        </div>
        <p className="header1 yellow padx"><FormattedMessage id="memebers" /></p>
      </div>
      <div className="row justify-content-around team">
        <div className="col-6 col-lg-4 pd my-5">
          <p className="text-center yellow header2">{fetch('2about05')}</p>
          <p className="text-center grey body">{fetch('2about06')}</p>
        </div>
        <div className="col-6 col-lg-4 pd my-5">
          <p className="text-center yellow header2">{fetch('2about07')}</p>
          <p className="text-center grey body">{fetch('2about08')}</p>
        </div>
        <div className="col-6 col-lg-4 pd my-5">
          <p className="text-center yellow header2">{fetch('2about09')}</p>
          <p className="text-center grey body">{fetch('2about10')}</p>
        </div>
        <div className="col-6 col-lg-4 pd my-5">
          <p className="text-center yellow header2">{fetch('2about11')}</p>
          <p className="text-center grey body">{fetch('2about12')}</p>
        </div>
        <div className="col-6 col-lg-4 pd my-5">
          <p className="text-center yellow header2">{fetch('2about13')}</p>
          <p className="text-center grey body">{fetch('2about14')}</p>
        </div>
        <div className="col-6 col-lg-4 pd my-5">
          <p className="text-center yellow header2">{fetch('2about15')}</p>
          <p className="text-center grey body">{fetch('2about16')}</p>
        </div>
      </div>
      <p className="header3 grey text-center thesePadding">{fetch('2about17')}</p>
      <div className="spacer2"></div>
      <div className="textContainer">
        <div className={lang === 'en' ? "text-left" : "text-right"}>
          <p className="header1 yellow">{fetch('2about18')}</p>
          <p className="header3 white">{fetch('2about19')}</p>
        </div>
      </div>
    </Layout>
  )
}

export default About;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 75) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    AboutUs_Cover: file(relativePath: { eq: "about/AboutUs_Cover.png" }) {
      ...fluidImage
    }
    AboutUs_CoverAr: file(relativePath: { eq: "about/test.png" }) {
      ...fluidImage
    }
  }
`;